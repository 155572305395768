import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons/faCircle';

import TopicsSvc from '../../../services/TopicsSvc';
import withComponentName from '../../../decorators/withComponentName';
import * as authentication from '../../../actions/authentication.actions';
import Styles from './styles.module.scss';

const cx = classNames.bind(Styles);

const Item = (props) => {
  const {
    item,
    activePath,
    checkActivity,
    expanded,
    path,
    children,
    social,
    isMobile,
    isSmall,
    leftBarOpened,
    listItemType,
    homepageLeftbarItem,
    openedActivePathTab,
    subMenuOpened,
    parentMenuExpanded,
    isSubMenu,
    isRailsContext,
    matchFixURL,
    token,
    actions,
    ...otherProps
  } = props;

  const [delayLeftBar, setDelayLeftBar] = useState(leftBarOpened);

  useEffect(() => {
    setTimeout(() => {
      setDelayLeftBar(leftBarOpened);
    }, 300);
  }, [leftBarOpened]);
  const [slugList, setSlugList] = useState([]);

  useEffect(() => {
    async function fetchTopicClasses() {
      const topicClasses = await TopicsSvc.getTopicClasses();
      const slugs = topicClasses.map((topic) => (
        topic.slug
      ));
      setSlugList(slugs);
    }

    fetchTopicClasses();
  }, []);
  let active;

  if (item.href.startsWith('http')) {
    const href = `/ ${item.href.split('/').slice(3).join('/')}`;
    active = (activePath || '').startsWith(href);
  } else if (matchFixURL || item.matchFixURL) {
    if ((item.href.includes('sectors')
      || item.href.includes('locations')
      || item.href.includes('/watchlists/manage')
      || item.href.includes('premium-publications') || item.href.includes('/directory/filings')
      || item.href.includes('investor-relations') || item.href.includes('/directory/esg')
      || item.href.includes('/directory/companies-insights/uk'))
      && (activePath || '').startsWith(`/${item.href.split('/').slice(1, 4).join('/')}`) && item.href !== '') {
      active = true;
    } else if ((item.otherHref || []).some((s) => (activePath || '').startsWith(s) || activePath === item.href)
      && !(activePath.includes('sectors')
      || activePath.includes('locations') || activePath.includes('premium-publications')
      || activePath.includes('companies-insights'))) {
      active = true;
    } else {
      active = activePath === item.href;
    }
  } else if ((activePath || '').startsWith(item.href)
    && !(activePath.includes('sectors')
      || activePath.includes('topic-class')
      || activePath.includes('locations')
      || activePath.includes('manage')
      || activePath.includes('premium-publications')
      || activePath.includes('companies-insights') || slugList.some((slug) => activePath.includes(slug))) && item.href !== '') {
    active = true;
  } else {
    active = (item.otherHref || []).some((s) => (activePath || '').startsWith(s) || activePath === item.href);
  }

  if (active && typeof window === 'object' && openedActivePathTab) {
    setTimeout(() => openedActivePathTab(item.menuCategory));
  }

  const isBookmarkAvailable = (token && item.name === 'Bookmarks') ? item.name : false;
  const permissions = useSelector(({ subscriptions }) => (
    subscriptions.permissions
  ));

  const noAccess = item.checkAccess && !(permissions?.features || []).includes(item.checkAccess);
  const isDisabled = noAccess || item.disabled;

  const extraProps = {};
  if (noAccess) {
    extraProps.new = false;
    extraProps.comingSoon = true;
    extraProps.disabled = isDisabled;
  }

  const currentItem = {
    ...item,
    ...extraProps,
  };

  const itemClass = cx(
    item.productsTabs
      ? 'item-subTabs' : 'item',
    {
      active,
      'left-bar-opened': leftBarOpened,
      'submenu-link-item': subMenuOpened,
      expanded,
      'parent-menu-expanded': parentMenuExpanded && !leftBarOpened, // do not close products tab on outside click
      social,
      'header-Item': listItemType === 'headerItem',
      'menu-list-item': listItemType === 'menuListItem',
      'sub-menu-list-item': listItemType === 'subMenuListItem', // submenus list items
      'is-mobile': isMobile,
      'is-small': isSmall,
      'light-menu-item': homepageLeftbarItem,
      'is-sub-menu': isSubMenu,
      [item.class]: item.class,
      disabled: isDisabled,
      is_new: item.new,
      isPremiumPublication: active && item.id === 62,
      isPremiumPublicationIcon: item.id === 62,
    },
  );

  const innerComponentStore = (
    <InnerComponent
      item={currentItem}
      leftBarOpened={leftBarOpened}
      delayLeftBar={delayLeftBar}
      isActive={active}
      token={token}
      isBookmarkAvailable={isBookmarkAvailable}
      lightLeftBar={homepageLeftbarItem}
      isDisabled={isDisabled}
    >
      {children}
    </InnerComponent>
  );

  const extraLinkProps = {
    className: itemClass,
    onClick: (e) => checkActivity(e, path === item.href, currentItem),
    ...otherProps,
  };

  if (isDisabled) {
    return (
      <div
        {...extraLinkProps}
        onClick={() => {}}
      >
        {innerComponentStore}
      </div>
    );
  }

  if (isRailsContext || item.isReload) {
    return (
      <a
        href={item.href}
        {...extraLinkProps}
      >
        {innerComponentStore}
      </a>
    );
  }

  return (
    <Link
      to={item.href}
      {...extraLinkProps}
    >
      {innerComponentStore}
    </Link>
  );
};

const InnerComponent = ({
  item,
  leftBarOpened,
  children,
  delayLeftBar,
  isActive,
  isBookmarkAvailable,
  lightLeftBar,
  isDisabled,
}) => {
  const isBookmarksTab = item.name === 'Bookmarks' ? isBookmarkAvailable : item.name;
  const delayHideTT = isBookmarksTab ? { show: 0, hide: 0 } : { show: 0, hide: 1000 };
  const dispatch = useDispatch();

  const setAuthModal = () => {
    if (item.name !== 'Bookmarks') return;
    dispatch(authentication.setAuthModalOpened(true));
  };

  const newIcon = (
    item.new && (
      <span
        className={cx('new', { opened: leftBarOpened })}
        style={{ color: lightLeftBar ? '#ffffff' : '#172c40' }}
      >
        NEW
      </span>
    )
  );
  const newTooltipIcon = (
    item.new && (
      <span
        className={cx('new')}
        style={{ color: '#172c40' }}
      >
        NEW
      </span>
    )
  );

  return (item.productsTabs ? (
    <>
      <OverlayTrigger
        placement="right"
        overlay={(
          <Tooltip
            id={`left_bar_tooltip_${item.id}`}
            bsPrefix={cx({ left_bar_tooltip_hidden: leftBarOpened || delayLeftBar })}
          >
            {item.name}
            {
              item.comingSoon && (
                <span className={Styles['coming-soon-subTabs']}>
                  coming soon
                </span>
              )
            }
            {newTooltipIcon}
          </Tooltip>
        )}
      >
        <div className={Styles.icon}>
          {(isActive && item.iconActive) ? item.iconActive : item.icon}
        </div>
      </OverlayTrigger>
      <div className={cx('link-subTabs', { disabled: isDisabled })}>
        <FontAwesomeIcon icon={faCircleSolid} />
        <div className={cx('itemName-subTabs')}>
          {item.name}
        </div>
      </div>
      {children}
      {
        item.comingSoon && (
          <span className={Styles['coming-soon-subTabs']}>
            coming soon
          </span>
        )
      }
      {newIcon}
    </>
  ) : (
    <>
      <OverlayTrigger
        placement="right"
        delay={delayHideTT}
        overlay={
          (
            <Tooltip
              id={`left_bar_tooltip_${item.id}`}
              bsPrefix={cx({ left_bar_tooltip_hidden: leftBarOpened || delayLeftBar })}
            >
              {!isBookmarksTab ? (
                <span>
                  Please
                  {' '}
                  <span onClick={setAuthModal} className={cx('left_bar_bookmarks_tooltip')}>login</span>
                  {' '}
                  to access this features
                </span>
              ) : item.name}
              {
                item.comingSoon && (
                  <span className={Styles['coming-soon']}>
                    coming soon
                  </span>
                )
              }
              {newTooltipIcon}
            </Tooltip>
          )
        }
      >
        <div className={Styles.icon}>
          {(isActive && item.iconActive) ? item.iconActive : item.icon}
        </div>
      </OverlayTrigger>
      <div className={cx('link', { disabled: isDisabled })}>
        <div className={cx('itemName')}>{item.name}</div>
        {
          item.comingSoon && (
            <span className={Styles['coming-soon']}>
              coming soon
            </span>
          )
        }

        {newIcon}
      </div>
      {children}
    </>
  ));
};

Item.defaultProps = {
  checkActivity: () => { },
  path: '',
  social: false,
  activePath: null,
  expanded: false,
};

Item.propTypes = {
  item: PropTypes.shape({
    href: PropTypes.string,
    icon: PropTypes.node,
    name: PropTypes.string,
  }).isRequired,
  path: PropTypes.string,
  activePath: (props, propName, componentName) => {
    if (props[propName] !== null && typeof props[propName] !== 'string') {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. Validation failed.`,
      );
    }
  },
  expanded: PropTypes.bool,
  checkActivity: PropTypes.func,
  social: PropTypes.bool,
};

export default withComponentName(Item);
